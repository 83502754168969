<template>
  <div class="contract-approval">
    <list-top 
      title="合同审批" 
      ref="topRef"
      @handleStatus="handleStatus"
    />
    <div class="list-wrap">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <list-item 
          @nextUrl="handleNextUrl"
          :detailList="item.contractType === 1 ? salesDetailList : purchaseDetailList" 
          :detail="item"
          leftName="合同类型"
          :leftValue="`${item.contractType === 1 ? '销售_' : ''}${handleContractType(item)}`"
          rightName="合同编号"
          :rightValue="item.contractNo"
          :icon="handleContractType(item)[0]"
          :status="status"
          :billNo="item.agreementAuditAuthority ? item.agreementBillNo : item.contractNo"
          v-for="(item, index) in list" 
          :key="index" 
          @getList="handleStatus"
        />
      </van-list>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {
  contractList,
} from "@/api/reimburse";
import {
  NavBar,
  Button,
  List,
} from "vant";
Vue.use(NavBar).use(Button).use(List);
  
import ListTop from '@/components/business/approvalListTop/index.vue'
import ListItem from '@/components/business/approvalListItem/index.vue'
export default {
  name: 'contractApproval',
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      status: 2,
      list: [],
      loading: false,
      finished: false,
      salesDetailList: [
       {
        title: "申请人",
        key: "createUser"
       },
       {
        title: "服务产品",
        key: "productName"
       },
       {
        title: "客户",
        key: "customerName"
       },
       {
        title: "所属公司",
        key: "serviceCompanyName"
       },
       {
        title: "所属部门",
        key: "departmentName"
       },
      ],
      purchaseDetailList: [
       {
        title: "申请人",
        key: "createUser"
       },
       {
        title: "供应商",
        key: "supplierName"
       },
       {
        title: "所属公司",
        key: "serviceCompanyName"
       },
       {
        title: "所属部门",
        key: "departmentName"
       },
      ],
      show: false,
      itemType: ['新签', '续签', '换签', '协议']
    };
  },
  components: {
    ListTop,
    ListItem
  },
  mounted() {
    this.getList()
  },

  methods: {
    handleStatus(e){
      this.list = []
      this.page = 1;
      this.status = e;
      this.getList()
    },
    onLoad() {
      if(this.total >= this.list.length){
        this.page += 1
        this.getList()
      }
    },
    async getList(){
      this.loading = true;
      this.finished = false;
      await contractList({
        page: this.page,
        limit: this.limit,
        status: this.status,
      }).then(res => {
        let detail = res?.resultData
        if(this.page === 1){
          this.list = detail?.list
        } else {
          this.list.push(...detail?.list)
        }
        this.total = detail?.total;
        if(detail?.total === this.list.length){
          this.finished = true;
        }
      })
      this.loading = false;
    },
    handleNextUrl(item){
      let name, auditAuthority, agreementAuditAuthority;
      if(item.contractType === 1){
        if(item.agreementBillNo){
          name = 'agreementView'
        } else {
          name = 'contractView'
        }
        agreementAuditAuthority = item.agreementAuditAuthority;
        auditAuthority = item.auditAuthority
      } else {
        name = 'contractBuy'
        auditAuthority = item.auditAuthority;
      }
      this.$router.push({
        name,
        query: {
          reimId: item.contractId,
          status: item.status,
          auditAuthority,
          agreementAuditAuthority,
        }
      })
    },
    handleContractType(item){
      if(item.contractType === 1){
        return item.contractPropertiesName
      } else {
        return '采购'
      }
    },
  }  
}
</script>
<style lang="scss">
.contract-approval{
  overflow: hidden;
  height: 100vh;
  .list-wrap{
    overflow: auto;
    height: calc(100vh - .92rem);
  }
}
</style>






